var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  /**
   * Source: https://github.com/timoxley/to-array
   *
   * Convert an array-like object into an `Array`.
   * If `collection` is already an `Array`, then will return a clone of `collection`.
   *
   * @param {Array | Mixed} collection An `Array` or array-like object to convert e.g. `arguments` or `NodeList`
   * @return {Array} Naive conversion of `collection` to a new `Array`.
   * @api public
   */
  exports = function toArray(collection) {
    if (typeof collection === "undefined") return [];
    if (collection === null) return [null];
    if (collection === window) return [window];
    if (typeof collection === "string") return [collection];
    if (isArray(collection)) return collection;
    if (typeof collection.length != "number") return [collection];
    if (typeof collection === "function" && collection instanceof Function) return [collection];
    var arr = [];

    for (var i = 0; i < collection.length; i++) {
      if (Object.prototype.hasOwnProperty.call(collection, i) || i in collection) {
        arr.push(collection[i]);
      }
    }

    if (!arr.length) return [];
    return arr;
  };

  function isArray(arr) {
    return Object.prototype.toString.call(arr) === "[object Array]";
  }

  return exports;
}