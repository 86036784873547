import { dew as _toArrayDew } from "./to-array";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var bind = window.addEventListener ? "addEventListener" : "attachEvent",
      unbind = window.removeEventListener ? "removeEventListener" : "detachEvent",
      prefix = bind !== "addEventListener" ? "on" : "",
      toArray = _toArrayDew();
  /**
   * Bind `el` event `type` to `fn`.
   *
   * @param {Element} el, NodeList, HTMLCollection or Array
   * @param {String} type
   * @param {Function} fn
   * @param {Boolean} capture
   * @api public
   */


  exports.bind = function (el, type, fn, capture) {
    el = toArray(el);

    for (var i = 0; i < el.length; i++) {
      el[i][bind](prefix + type, fn, capture || false);
    }
  };
  /**
   * Unbind `el` event `type`'s callback `fn`.
   *
   * @param {Element} el, NodeList, HTMLCollection or Array
   * @param {String} type
   * @param {Function} fn
   * @param {Boolean} capture
   * @api public
   */


  exports.unbind = function (el, type, fn, capture) {
    el = toArray(el);

    for (var i = 0; i < el.length; i++) {
      el[i][unbind](prefix + type, fn, capture || false);
    }
  };

  return exports;
}